
import { ScholarDto, GuardianDto, EmergencyContactDto, AuthorisedPickupDto } from '@/Model/model';
import APP_CONST from '@/constants/AppConst';
import headerStore from '@/store/modules/headerStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Component, Prop, Vue } from 'vue-property-decorator';

enum ContactType {
  Guardian = 'Guardian',
  EmergencyContact = 'Emergency Contact',
  AuthorizedPickup = 'Authorized pickup'
}

interface ParticipantContact {
  key: string;
  hasFullName: boolean;
  hasPhoneNumber: boolean;
  contactType: ContactType;
  fullName: string;
  phoneNumber?: string;
}

@Component({})
export default class ParticipantDetails extends Vue {
  @Prop({ required: true }) selectedParticipant!: ScholarDto;

  get participantFullName(): string {
    return this.getFullName(this.selectedParticipant.firstName, this.selectedParticipant.lastName);
  }

  get viewParticipantHref(): string {
    return `${process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL}family-details/scholar?redirectTo=participants`;
  }

  get editParticipantHref(): string {
    return `${process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL}family-details/scholar?mode=edit&redirectTo=participants`;
  }

  get participantContacts(): Array<ParticipantContact> {
    const contacts = [
      ...this.mapToContact(this.selectedParticipant.guardianDetails, ContactType.Guardian),
      ...this.mapToContact(this.selectedParticipant.emergencyContacts, ContactType.EmergencyContact),
      ...this.mapToContact(this.selectedParticipant.authorisedPickups, ContactType.AuthorizedPickup),
    ] as ParticipantContact[];

    return contacts;
  }

  handleEnrollmentUserRoleAccess(): void {
    const { roleId } = APP_UTILITIES.coreids();
    const accountId = APP_UTILITIES.getCookie('accountId');
    const accountName = APP_UTILITIES.getCookie('accountName');
    const userRoles = APP_UTILITIES.getCookie('user_role') || '[{}]';
    const userRoleId = APP_UTILITIES.getCookie('okta_user_role');
    const userData = JSON.parse(userRoles);
    userData[0].roleId = Number(userRoleId);

    if (roleId === APP_CONST.ROLE_TYPE_SUPER_ADMIN || roleId === APP_CONST.ROLE_TYPE_BX_ADMIN) {
      userData[0].roleId = APP_CONST.ACCOUNT_ADMIN_ROLE_ID;
      userData[0].accountId = accountId;
      APP_UTILITIES.setCookie('super_user_drilldown', 'true', 1);
      APP_UTILITIES.setCookie('super_user_drilldown_to_account', 'true', 1);
      APP_UTILITIES.setCookie('accountName', String(accountName), 1);
      headerStore.mutateBxState(true);
    }
    APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
  }

  getFullName(...names: string[]): string {
    return [...names].filter(Boolean).join(' ');
  }

  mapToContact(records: Array<GuardianDto | EmergencyContactDto | AuthorisedPickupDto>, contactType: ContactType): Array<ParticipantContact> {
    return records.map((r) => ({
      key: r.guid,
      hasFullName: !!(r.firstName && r.lastName),
      hasPhoneNumber: !!r.phone,
      contactType: contactType,
      fullName: this.getFullName(r.firstName, r.lastName),
      phoneNumber: r.phone && APP_UTILITIES.formatNumber(r.phone)
    }))
  }
}
